import coreModule from '../../core_module';
import appEvents from 'app/core/app_events';
import i18n from 'i18next';

export class HelpCtrl {
  tabIndex: any;
  shortcuts: any;

  /** @ngInject */
  constructor() {
    this.tabIndex = 0;
    this.shortcuts = {
      Global: [
        { keys: ['g', 'h'], description: i18n.t('Go to Home Dashboard') },
        { keys: ['g', 'p'], description: i18n.t('Go to Profile') },
        { keys: ['s', 'o'], description: i18n.t('Open search') },
        { keys: ['s', 's'], description: i18n.t('Open search with starred filter') },
        { keys: ['s', 't'], description: i18n.t('Open search in tags view') },
        { keys: ['esc'], description: i18n.t('Exit edit/setting views') },
      ],
      Dashboard: [
        { keys: ['mod+s'], description: i18n.t('Save dashboard') },
        { keys: ['d', 'r'], description: i18n.t('Refresh all panels') },
        { keys: ['d', 's'], description: i18n.t('Dashboard settings') },
        { keys: ['d', 'v'], description: i18n.t('Toggle in-active / view mode') },
        { keys: ['d', 'k'], description: i18n.t('Toggle kiosk mode (hides top nav)') },
        { keys: ['d', 'E'], description: i18n.t('Expand all rows') },
        { keys: ['d', 'C'], description: i18n.t('Collapse all rows') },
        { keys: ['d', 'a'], description: i18n.t('Toggle auto fit panels (experimental feature)') },
        { keys: ['mod+o'], description: i18n.t('Toggle shared graph crosshair') },
      ],
      'Focused Panel': [
        { keys: ['e'], description: i18n.t('Toggle panel edit view') },
        { keys: ['v'], description: i18n.t('Toggle panel fullscreen view') },
        { keys: ['p', 's'], description: i18n.t('Open Panel Share Modal') },
        { keys: ['p', 'd'], description: i18n.t('Duplicate Panel') },
        { keys: ['p', 'r'], description: i18n.t('Remove Panel') },
        { keys: ['p', 'l'], description: i18n.t('Toggle panel legend') },
      ],
      'Time Range': [
        { keys: ['t', 'z'], description: i18n.t('Zoom out time range') },
        {
          keys: ['t', '<i class="fa fa-long-arrow-left"></i>'],
          description: i18n.t('Move time range back'),
        },
        {
          keys: ['t', '<i class="fa fa-long-arrow-right"></i>'],
          description: i18n.t('Move time range forward'),
        },
      ],
    };
  }

  dismiss() {
    appEvents.emit('hide-modal');
  }
}

export function helpModal() {
  return {
    restrict: 'E',
    templateUrl: 'public/app/core/components/help/help.html',
    controller: HelpCtrl,
    bindToController: true,
    transclude: true,
    controllerAs: 'ctrl',
    scope: {},
  };
}

coreModule.directive('helpModal', helpModal);
