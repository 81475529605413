import React, { FC } from 'react';
import { translate } from 'react-i18next';

export interface Props {
  child: any;
  t: any;
}

const DropDownChild: FC<Props> = props => {
  const { child, t } = props;
  const listItemClassName = child.divider ? 'divider' : '';

  return (
    <li className={listItemClassName}>
      <a href={child.url}>
        {child.icon && <i className={child.icon} />}
        {t(child.text)}
      </a>
    </li>
  );
};

export default translate()(DropDownChild);
