import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { reactI18nextModule } from 'react-i18next';

i18n
  .use(reactI18nextModule)
  .use(XHR)
  .init({
  lng: localStorage.getItem("NG_TRANSLATE_LANG_KEY"), // If not given, i18n will detect the browser language.
  //debug: true,
  fallbackLng: 'en', // Default is dev
  //useCookie: true,
  //useLocalStorage: true,
  interpolation: {
  escapeValue: false // not needed for react!!
  },

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
  },
  compatibilityJSON: 'v1',
  backend: {
    loadPath: '/public/i18n/{{lng}}.json'
  }
});

export default i18n;