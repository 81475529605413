import React, { Component } from 'react';
import i18n from 'i18next';

export interface Props {
  model: any;
}

class EmptyListCTA extends Component<Props, any> {
  render() {
    const {
      title,
      buttonIcon,
      buttonLink,
      buttonTitle,
      onClick,
      proTip,
      proTipLink,
      proTipLinkTitle,
      proTipTarget,
    } = this.props.model;
    return (
      <div className="empty-list-cta">
        <div className="empty-list-cta__title">{i18n.t(title)}</div>
        <a onClick={onClick} href={buttonLink} className="empty-list-cta__button btn btn-xlarge btn-primary">
          <i className={buttonIcon} />
          {i18n.t(buttonTitle)}
        </a>
        {i18n.t(proTip) && (
          <div className="empty-list-cta__pro-tip">
            <i className="gicon gicon-playlists" />
            {i18n.t(proTip)}
            <a className="text-link empty-list-cta__pro-tip-link" href={proTipLink} target={proTipTarget}>
              {i18n.t(proTipLinkTitle)}
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default EmptyListCTA;
