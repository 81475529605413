import React, { ChangeEvent, PureComponent } from 'react';

import { FormLabel, Select, Switch } from '@grafana/ui';
import { getBackendSrv, BackendSrv } from 'app/core/services/backend_srv';

import { DashboardSearchHit } from 'app/types';
//import { withNamespaces } from 'react-i18next';
import i18n from 'i18next';
import Modal from 'react-awesome-modal';
import { TestRuleResult } from 'app/features/alerting/TestRuleResult';


export interface Props {
  resourceUri: string;
  t: any;
}

export interface State {
  homeDashboardId: number;
  theme: string;
  timezone: string;
  message1: number;
  message2: number;
  message3: number;
  message4: number;
  message5: number;
  message6: number;
  message7: number;
  coilnumber: number;
  callnow: number;
  mttf: number;
  dashboards: DashboardSearchHit[];
}

const themes = [{ value: '', label: 'Default' }, { value: 'dark', label: 'Dark' }, { value: 'light', label: 'Light' }];

const timezones = [
  { value: '', label: 'Default' },
  { value: 'browser', label: 'Local browser time' },
  { value: 'utc', label: 'UTC' },
];

const coilnumbers = [
  { value: 1, label: 1 },
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
  { value: 60, label: 60 },
  { value: 70, label: 70 },
  { value: 80, label: 80 },
  { value: 90, label: 90 },
  { value: 100, label: 100 },
];


export class SharedPreferences extends PureComponent<Props, State> {
  backendSrv: BackendSrv = getBackendSrv();

  constructor(props) {
    super(props);
    this.state = {
      homeDashboardId: 0,
      theme: '',
      timezone: '',
      message1: 0,
      message2: 0,
      message3: 0,
      message4: 0,
      message5: 0,
      message6: 0,
      message7: 0,
      coilnumber: 0,
      callnow: 0,
      mttf: 0,
      dashboards: [],
      visible: false,
    };
  }

  async componentDidMount() {
    const prefs = await this.backendSrv.get(`/api/${this.props.resourceUri}/preferences`);
    const dashboards = await this.backendSrv.search({ starred: true });

    if (prefs.homeDashboardId > 0 && !dashboards.find(d => d.id === prefs.homeDashboardId)) {
      const missing = await this.backendSrv.search({ dashboardIds: [prefs.homeDashboardId] });
      if (missing && missing.length > 0) {
        dashboards.push(missing[0]);
      }
    }

    this.setState({
      homeDashboardId: prefs.homeDashboardId,
      theme: prefs.theme,
      timezone: prefs.timezone,
      message1: prefs.message1,
      message2: prefs.message2,
      message3: prefs.message3,
      message4: prefs.message4,
      message5: prefs.message5,
      message6: prefs.message6,
      message7: prefs.message7,
      coilnumber: prefs.coilnumber,
      callnow: prefs.callnow,
      mttf: prefs.mttf,
      dashboards: [{ id: 0, title: 'Default', tags: [], type: '', uid: '', uri: '', url: '' }, ...dashboards],
    });
  }

  onSubmitForm = async event => {
    event.preventDefault();

    const { homeDashboardId, theme, timezone, message1, message2, message3, message4,
    message5, message6, message7, coilnumber, callnow, mttf } = this.state;

    await this.backendSrv.put(`/api/${this.props.resourceUri}/preferences`, {
      homeDashboardId,
      theme,
      timezone,
      message1,
      message2,
      message3,
      message4,
      message5,
      message6,
      message7,
      coilnumber,
      callnow,
      mttf,
    });
    window.location.reload();
  };

  onThemeChanged = (theme: string) => {
    this.setState({ theme });
  };

  onTimeZoneChanged = (timezone: string) => {
    this.setState({ timezone });
  };

  onHomeDashboardChanged = (dashboardId: number) => {
    this.setState({ homeDashboardId: dashboardId });
  };

  onMessage1Changed = () => {
    if (this.state.message1 === 1) {
      this.setState({ message1: 0});
    } else {
      this.setState({ message1: 1});
    }
  };

  onMessage2Changed = () => {
    if (this.state.message2 === 1) {
      this.setState({ message2: 0});
    } else {
      this.setState({ message2: 1});
    }
  };

  onMessage3Changed = () => {
    if (this.state.message3 === 1) {
      this.setState({ message3: 0});
    } else {
      this.setState({ message3: 1});
    }
  };

  onMessage4Changed = () => {
    if (this.state.message4 === 1) {
      this.setState({ message4: 0});
    } else {
      this.setState({ message4: 1});
    }
  };

  onMessage5Changed = () => {
    if (this.state.message5 === 1) {
      this.setState({ message5: 0});
    } else {
      this.setState({ message5: 1});
    }
  };

  onMessage6Changed = () => {
    if (this.state.message6 === 1) {
      this.setState({ message6: 0});
    } else {
      this.setState({ message6: 1});
    }
  };

  onNumToBoolean = (message: number) => {
    if (message === 1) {
      return true;
    } else {
      return false;
    }
  }

  onAllEnabled = () => {
    if (this.state.message1 ===1 && this.state.message2 === 1 && this.state.message3 === 1
        && this.state.message4 === 1 && this.state.message5 === 1 && this.state.message6 === 1) {
      return true;
    } else {
      return false;
    }
  }

  onCoilNumberChanged = (event: number) => {
    this.setState({ coilnumber: event });
  }

  onCallNow = () => {
    console.log("ONCALL");
    this.setState({ callnow: 1 });
  }

  openModal() {
    this.setState({ visible : true });
  }

  closeModal() {
    this.setState({ visible : false });
  }

  onMttfChange = (event: number) => {
    this.setState({ mttf: event});
  };

  onMessageAll = () => {
    this.setState({ message1: 1});
    this.setState({ message2: 1});
    this.setState({ message3: 1});
    this.setState({ message4: 1});
    this.setState({ message5: 1});
    this.setState({ message6: 1});
  }

  render() {
    const { theme, timezone, homeDashboardId, dashboards, message1, message2, message3, message4,
    message5, message6, message7, coilnumber,callnow, mttf } = this.state;
    const { t } = this.props;
    const Checkbox = props => <input type="checkbox" {...props} />;
    return (
      <form className="section gf-form-group" onSubmit={this.onSubmitForm} >
        <h3 className="page-heading">{i18n.t('Message Service')}</h3>
        {/* 3.Alle Meldung */}
        <div className="gf-form">
          <Switch
          label={i18n.t('Enable All')}
          checked={this.onAllEnabled()}
          onChange={this.onMessageAll}
          />
        </div>
        {/* <div className="gf-form">
          <span className="gf-form-label width-11">{i18n.t('Message Group')}</span>
        </div> */}
        {/* 2.Verbrauchsmaterial */}
        <div className="gf-form">
          <FormLabel
            width={12}
            tooltip = {i18n.t('messageInfo1')}
          >
            {i18n.t('Consumables')}
          </FormLabel>
          <div>
            <Switch
            label={i18n.t('Operators')}
            checked={this.onNumToBoolean(message1)}
            onChange={this.onMessage1Changed}
            />
          </div>
{/*           <div>
             <Switch
            label={i18n.t('Purchaser')}
            checked={this.onNumToBoolean(message2)}
            onChange={this.onMessage2Changed}
             />
          </div> */}
        </div>
        {/* 3.Ersatzteil */}
        <div className="gf-form">
          <FormLabel
            width={12}
            tooltip = {i18n.t('messageInfo3')}
          >
            {i18n.t('Spare parts')}
          </FormLabel>
          <div>
             <Switch
            label={i18n.t('Maintenance')}
            checked={this.onNumToBoolean(message3)}
            onChange={this.onMessage3Changed}
             />
          </div>
        </div>
        {/* 4.Check */}
        <div className="gf-form">
          <FormLabel
            width={12}
            tooltip = {i18n.t('messageInfo4')}
          >
            {i18n.t('Checks')}
          </FormLabel>
          <div>
             <Switch
              label={i18n.t('Maintenance')}
              checked={this.onNumToBoolean(message4)}
              onChange={this.onMessage4Changed}
             />
          </div>
        </div>
        {/* 5.Prozessueberwachung */}
        <div className="gf-form">
          <FormLabel
            width={12}
            tooltip = {i18n.t('messageInfo5')}
          >
            {i18n.t('Process monitoring')}
          </FormLabel>
          <div>
             <Switch
              label={i18n.t('Operators')}
              checked={this.onNumToBoolean(message5)}
              onChange={this.onMessage5Changed}
             />
          </div>
        </div>
        {/* 6.'Inventory strapping band */}
        <div className="gf-form">
          <FormLabel
            width={12}
            tooltip = {i18n.t('messageInfo6')}
          >
            {i18n.t('Inventory of bands')}
          </FormLabel>
          <div>
             <Switch
            label={i18n.t('Purchaser')}
            checked={this.onNumToBoolean(message6)}
            onChange={this.onMessage6Changed}
             />
          </div>
          <div>
            <input
            width={3}
            className="gf-form-input"
            value={coilnumber}
            // tslint:disable-next-line: radix
            onChange={event => this.onCoilNumberChanged(parseInt(event.target.value))}
            />
          </div>
          <div>
          <span className="gf-form-label width-11">{i18n.t('Coils ')}</span>
          </div>
        </div>
        {/* 7.MTTF */}
        <div className="gf-form">
          <FormLabel
            width={12}
            tooltip={i18n.t('messageInfo')}
          >
          {i18n.t('Maintenance duration')}
          </FormLabel>
          <FormLabel>
          {i18n.t('Maintenance')}
          </FormLabel>
          <div>
            <input
            width={3}
            className="gf-form-input"
            value={mttf}
            // tslint:disable-next-line: radix
            onChange={event => this.onMttfChange(parseInt(event.target.value))}
            />
          </div>
          <div>
          <span className="gf-form-label width-11">{i18n.t('Hours/Week')}</span>
          </div>
        </div>
        <div className="gf-form-button-row">
          <button type="submit" className="btn btn-primary">
            {i18n.t('Save')}
          </button>
        </div>
        <div className="gf-form-button-row" />

        <h3 className="page-heading">{i18n.t('Preferences')}</h3>
        <div className="gf-form">
          <span className="gf-form-label width-11">{i18n.t('UI Theme')}</span>
          <Select
            isSearchable={false}
            value={themes.find(item => item.value === theme)}
            options={themes}
            onChange={theme => this.onThemeChanged(theme.value)}
            width={20}
          />
        </div>
        <div className="gf-form">
          <FormLabel
            width={11}
            tooltip="Not finding dashboard you want? Star it first, then it should appear in this select box."
          >
            {i18n.t('Home Dashboard')}
          </FormLabel>
          <Select
            value={dashboards.find(dashboard => dashboard.id === homeDashboardId)}
            getOptionValue={i => i.id}
            getOptionLabel={i => i.title}
            onChange={(dashboard: DashboardSearchHit) => this.onHomeDashboardChanged(dashboard.id)}
            options={dashboards}
            placeholder="Chose default dashboard"
            width={20}
          />
        </div>
        <div className="gf-form">
          <label className="gf-form-label width-11">{i18n.t('Timezone')}</label>
          <Select
            isSearchable={false}
            value={timezones.find(item => item.value === timezone)}
            onChange={timezone => this.onTimeZoneChanged(timezone.value)}
            options={timezones}
            width={20}
          />
        </div>
        <div className="gf-form-button-row">
          <button type="submit" className="btn btn-primary">
            {i18n.t('Save')}
          </button>
        </div>

        <div className="gf-form-button-row">
          <button
            type="button"
            onClick={() => this.openModal()}
            className="btn btn-tcarecall">
          <div
            class="gicon gicon-tel">
          </div>
          {i18n.t('TITAN Care Call')}
          </button>
          <Modal
            visible={this.state.visible}
            width="400"
            height="200"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div class="titan-call-modal">
              <h1>{i18n.t('TITAN Care Call')}</h1>
              <p>{i18n.t('titanCallConfirm')}</p>
              <button
                onClick={() => this.onCallNow()}
                className="btn btn-primary">
              {i18n.t('Confirm')}
              </button>
              <a href="javascript:void(0);" onClick={() => this.closeModal()} >{i18n.t('Cancel')}</a>
            </div>
          </Modal>
        </div>
      </form>
    );
  }
}

export default SharedPreferences;
